@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300..700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  font-family: "Space Grotesk", sans-serif !important;
}

input:focus {
  outline: none !important;
}

input::-ms-input-placeholder {
  /* Edge 12-18 */
  color: #556172 !important;
}

input::placeholder,
textarea::placeholder {
  color: #556172 !important;
}

.ant-input-outlined:focus,
.ant-input-outlined:focus-within {
  border: 0 !important;
  box-shadow: none !important;
  /* background: #262b3a !important; */
}
.ant-select-selector :focus,
.ant-select-selector :focus-within {
  border: 0 !important;
  box-shadow: none !important;
  /* background: #262b3a !important; */
}

.ant-form-item{
  margin-bottom: 15px !important;
}

.ant-input-outlined,
.ant-select-selector {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
  border-radius: 5px !important;
  padding: 7px 10px !important;
}

a {
  cursor: pointer;
}

img {
  width: 100%;
}

body {
  margin: 0 !important;
  color: #fff;
  background: #050518;
}

/* Trending */

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


.ant-form-item-margin-offset {
  margin-bottom: 0 !important;
}

.ant-form-item .ant-form-item-label label{
  color: #91abc9 !important;
}

.connect-btn-condition{
  position: absolute;
  left: 1em;
  right: 1em;
  bottom: 1em;
}
.connect-btn-condition .wallet-adapter-dropdown {
  width: 100%;
}

.connect-btn-condition .wallet-adapter-button.wallet-adapter-button-trigger {
  background: linear-gradient(90deg, #8c0bf7, #50defd) !important;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.ant-layout {
  .ant-layout {
    background: #050518;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom;
    background-image: url(/src/assets/img/bg.png);
    background-position: center top;
      background-repeat: repeat-y;
  }
}

.ant-space-item {
  display: flex;
}

.ant-layout .ant-layout-sider,
.ant-menu-dark.menu-main,
.ant-menu-dark.menu-main > .ant-menu {
  background: #18182b;
}

.ant-menu.menu-main .ant-menu-item {
  font-size: 11px;
  padding: 0 !important;
}
.ant-menu.menu-main .ant-menu-item span{
  margin-inline-start: 0;
}

.ant-menu.menu-main .ant-menu-item .anticon{
  font-size: 18px;
}
.ant-menu-inline .ant-menu-item {
  height: 70px;
  line-height: 1.3;
}

.ant-menu.menu-main .ant-menu-item a{
  height: 60px;
  border-radius: 8px;
  margin: 7px;
}
.ant-menu.menu-main .ant-menu-item a:hover{
  background-color: #050518;
}

.ant-menu-dark.menu-launchpad-list,
.ant-menu-dark.menu-launchpad-list
  > .ant-menu
  .ant-menu.menu-main
  .ant-menu-item:first-child {
  height: 90px !important;
  display: flex;
  align-items: center;
  margin: 0 !important;
}

.ant-layout-header {
  height: 70px;
  display: flex;
  align-items: center;
}

.ant-menu-inline-collapsed-tooltip {
  display: none;
}

.ant-btn {
  padding: 0;
  margin-left: -20px;
}
.ant-layout .ant-layout-header{
  z-index: 999;
}
.ant-layout .ant-layout-header,
.ant-layout.ant-layout-footer {
  background: #1C0D34 !important;
}

.ant-menu.menu-main .ant-menu-item:first-child.ant-menu-item-selected {
  background: none !important;
}

.ant-menu.menu-main .ant-menu-item {
  display: flex;
  align-items: center;
}

.ant-menu-inline.menu-main .ant-menu-item,
.ant-menu-vertical.menu-main .ant-menu-item,
.ant-menu-inline.menu-main .ant-menu-submenu-title,
.ant-menu-vertical.menu-main .ant-menu-submenu-title {
  margin-inline: 0;
  width: 100%;
  border-radius: 0;
}

.ant-menu-dark.menu-main .ant-menu-item-selected,
.ant-menu-dark .menu-main > .ant-menu-item-selected {
  background: #18182b;
}

.menu-main .ant-menu-item-selected .ant-menu-title-content .title{
  color: #ff614b;
}



.wallet-adapter-button-trigger {
  background: linear-gradient(90deg, #8c0bf7 0%, #50defd 100%) !important;
  border-radius: 7px !important;
  height: 40px !important;
  line-height: 40px !important;
  padding: 0 15px !important;
  font-size: 14px !important;
  font-weight: normal !important;
}
a:hover {
  color: #fff;
}

.bg-primary-gradient {
  background: rgb(171, 94, 254);
  background: linear-gradient(180deg, rgba(171, 94, 254, 1) 0%, rgba(87, 68, 255, 1) 100%) !important;
}
.bg-secondary-gradient {
  
  background: linear-gradient(270deg, #ff614b 0%, #ff4a85 100%);
}


.text-primary-gradient {
  background: linear-gradient(270deg, #ff614b 0%, #ff4a85 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-secondary-gradient {
  background: linear-gradient(90deg, #8c0bf7 0%, #50defd 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ant-picker.ant-picker-outlined {
  border: 0 !important;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
  color: #fff !important;
}

.ant-select-selection-item {
  color: #fff !important;
}

.ant-select .ant-select-arrow {
  color: #ff614b !important;
}

@media screen and (max-width: 768px) {
  .wallet-adapter-button-trigger {
    border-radius: 10px !important;
    height: 40px !important;
    line-height: 40px;
    padding: 0 15px !important;
    font-size: 14px !important;
  }
  .slider {
    width: 100%;
  }
  .slide-track {
    gap: 10px;
  }
}

.player-item{
  border-right: 15px solid #fff;
}

.player-item.color-f97066{
  border-color: #f97066;
}
.player-item.color-5623c4{
  border-color: #5623c4;
}
.player-item.color-e5e7eb{
  border-color: #e5e7eb;
}
.player-item.color-ccfd07{
  border-color: #ccfd07;
}
.player-item.color-c11574{
  border-color: #c11574;
}
.player-item.color-2ed3b7{
  border-color: #2ed3b7;
}
.player-item.color-854CFF{
  border-color: #854CFF;
}
.player-item.color-968A64{
  border-color: #968A64;
}
.player-item.color-B56C76{
  border-color: #B56C76;
}
.player-item.color-0EB75F{
  border-color: #0EB75F;
}
.player-item.color-7FCFDF{
  border-color: #7FCFDF;
}
.player-item.color-183D09{
  border-color: #183D09;
}
.player-item.color-733C40{
  border-color: #733C40;
}
.player-item.color-e5e7eb{
  border-color: #e5e7eb;
}


.players-list{
  position: absolute;
  left: 0.75em;
  right: 0.75em;
  top: 60px;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
}

@media screen and (max-width: 1024px) {
  .players-list{
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    /* height: 280px; */
    margin-bottom: 170px;
  }
}

.player-item{
  /* opacity: 0.75; */
  cursor: pointer;
  transition: 0.3s;
  background-color: #080222;
}
.player-item:hover, .player-item:focus, .player-item.hover{
  opacity: 1;
  background-color: #3d3d67;
}
.player-item.win{
  opacity: 1;
  background-color: #3d3d67;
}

.ant-modal-content {
  background: #1c0d34 !important;
  border: 1px solid #735893 !important;
  border-radius: 20px !important;
  padding: 40px 17px 5px !important;
}
.ant-modal {
  padding: 0;
  border-radius: 8px;
}

.ant-modal .ant-modal-close{
  background: #271b40 !important;
    border-radius: 12px;
    height: 38px;
    width: 38px;
}
.ant-modal .ant-modal-close-x svg {
  fill: #fff;
}

.ant-modal-mask{
  background-color: transparent !important;
  backdrop-filter: blur(8px);
}

.error{
  color: #ff4d4f;
  font-size: 12px;
  display: block;
  margin-top: 5px;
}

.spinner{
  padding-top: 100%;
  position: relative;
}
.spinner-inner{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.spinwrp{
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
}

.spinchart.spin{
  position: relative;
  z-index: 7;
  -webkit-transition: cubic-bezier(0.19, 1, 0.22, 1) 10s;
  transition: cubic-bezier(0.19, 1, 0.22, 1) 10s;
}



.spinarrow{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: #080222;
  border-radius: 50%;
  z-index: 7;
}



.spinwrp:after{
  content: "";
  width: 45px;
  height: 40px;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: -15px;
  z-index: 9;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(/src/assets/img/spinner-arrow.svg);
}

.spinner-relative{
  height: 300px;
  overflow: hidden;
  /* border: 1px solid #735893; */
  border-radius: 18px;
  padding: 10px 10px 0 10px;
  background-color: #1c0d34;
}

.spincontent{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 220px;
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  margin: auto;
  background: #271b40;
  border: 3px solid #413657;
  border-radius: 50%;
}

.spincontent .inner{
  z-index: 9;
  transform: translateY(-40%);
}

.round-info{
   
}

@media screen and (max-width: 1024px) {
  .round-info{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
}

.table-rewards .ant-table-cell{
  background-color: #18182b !important;
  color: #fff !important;
  border: none;
  border-bottom: 1px solid #ffffff0e !important;
}
.table-rewards .ant-table-row:last-child .ant-table-cell{

  border-bottom: 0 !important;
}
.table-rewards .ant-table-cell::before{
  content: none !important;
}

.table-rewards .ant-table {
  background-color: transparent !important;
}
.table-rewards .ant-table-content{
  border-radius: 15px;
    overflow: hidden;
    border-bottom: 1px solid #ffffff29 !important;
}



.amount .plus{
  color: #15ad61;
}
.amount .minus{
  color: #ff614b;
}


.ant-pagination-item{
  background: #18182b !important;
  border: none !important;
}

.ant-pagination-item a{
  color: #fff !important;
}

.ant-pagination-item-active{
  background: linear-gradient(90deg, #8c0bf7 0%, #50defd 100%) !important;
}


.button{
  height: 40px;
  padding: 0 14px;
  border-radius: 12px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  background-color: #271B40;
  display: inline-block;
  line-height: 40px;
}

.button.is-outline{
  border: 1px solid #735893;
}

.button.is-gradient{
  background: radial-gradient(circle at 100% 100%, #271b40 0, #271b40 11px, transparent 11px) 0% 0%/12px 12px no-repeat,
            radial-gradient(circle at 0 100%, #271b40 0, #271b40 11px, transparent 11px) 100% 0%/12px 12px no-repeat,
            radial-gradient(circle at 100% 0, #271b40 0, #271b40 11px, transparent 11px) 0% 100%/12px 12px no-repeat,
            radial-gradient(circle at 0 0, #271b40 0, #271b40 11px, transparent 11px) 100% 100%/12px 12px no-repeat,
            linear-gradient(#271b40, #271b40) 50% 50%/calc(100% - 2px) calc(100% - 24px) no-repeat,
            linear-gradient(#271b40, #271b40) 50% 50%/calc(100% - 24px) calc(100% - 2px) no-repeat,
            linear-gradient(transparent 0%, #ab5efe 0%, #5744ff 100%);
  box-sizing: content-box;
}

.countdown button{
  background: #FDDD33;
  background: linear-gradient(to right, #DDAC74 0%, #B975BF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  height: 36px;
  line-height: 36px;
  width: 90px;
  font-size: 20px;
}


.slick-control .button{
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
}